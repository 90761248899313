<template>
  <section
    v-if="CouponAddonStatus"
    class="cart-coupon-addon__container section promotion-sec"
    :class="{'addon-dialog': showType == 'addonDialog'}"
  >
    <!-- 优惠券凑单促销头组件,增加注释节点 -->
    <!-- PS:根节点层级的注释会影响测试环境打包时, 父组件透传属性如class之类 -->
    <div class="section-l">
      <component
        :is="addOnIconName"
        :size="showType == 'addonDialog' ? '24px' : '18px'"
      />
    </div>
    <div class="section-r">
      <div class="coupon-addon-content">
        <div class="item-content-tip">
          <div 
            class="promotion-full-tip"
            v-html="couponAddonTip"
          ></div>
          <div
            v-if="couponAddon"
            class="item-content-tip__process"
          >
            <div
              class="item-content-tip__process-bg"
              :style="{ width: `${couponProcessCount}%` }"
            ></div>
          </div>
        </div>
        <div
          v-if="couponAddon && showAction" 
          class="cart_item-action"
          @click.stop="handlePromotionItemActionClick"
        >
          <span>{{ language.SHEIN_KEY_PC_15215 }}</span>
          <sui_icon_more_right_12px
            :is-rotate="locals.GB_cssRight"
            size="12px"
          />
        </div>
      </div>
      <ClientOnly>
        <count-down-time
          v-if="couponAddonShowCountDown"
          class="coupon-addon__count-down"
          show-type="inline-promotion"
          :time-stamp="Number(couponAddon.end_date)"
          :show-end-tips="true"
        />
      </ClientOnly>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { sui_icon_selected_24px, sui_icon_more_right_12px, sui_icon_coupon_discount_24px } from '@shein-aidc/icon-vue3'

export default {
  name: 'CouponAddon',
  components: {
    sui_icon_selected_24px,
    sui_icon_more_right_12px,
    sui_icon_coupon_discount_24px
  },
  props: {
    showAction: {
      type: Boolean,
      default: true,
    },
    showType: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState(['language', 'ABTParams', 'locals']),
    ...mapGetters(['couponAddon', 'couponDiscount', 'couponProcessCount', 'couponAddonTip', 'couponAddonShowCountDown', 'CouponAddonStatus']),
    addOnIconName() {
      return this.couponDiscount ? 'sui_icon_selected_24px' : 'sui_icon_coupon_discount_24px'
    },
  },
  watch: {
    CouponAddonStatus: {
      handler(val) {
        if(val) {
          if(typeof window === 'undefined') {
            return
          }
          daEventCenter.triggerNotice({
            daId: '1-7-3-23',
            extraData: {
              is_satisfied: val == 'couponAddon' ? 0 : 1,
              code: this.couponAddon?.coupon || '-'
            }
          })
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations(['updateField', 'updateAddForCoupon']),
    handlePromotionItemActionClick() {
      if(this.couponAddon) {
        this.updateAddForCoupon({ 
          show: true, 
          type: 'coupon', 
          coupon: this.couponAddon.coupon,
          state: 'coupon_add',
        })
        daEventCenter.triggerNotice({
          daId: '1-7-3-24',
          extraData: {
            is_satisfied: this.CouponAddonStatus == 'couponAddon' ? 0 : 1,
            code: this.couponAddon?.coupon || '-'
          }
        })
      }
    }
  },
}
</script>

<style lang="less" scoped>
.cart-coupon-addon__container {
  .section {
    height: 100%;
  }
  .section-r {
    max-width: unset;
    overflow: hidden;
  }
  .coupon-addon-content {
    margin-top: 4px;
    display: flex;
    justify-content: space-between;
  }
  .item-content-tip {
    margin-right: 12px;
    overflow: hidden;
    .promotion-full-tip {
      font-size: 13px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }    
    .item-content-tip__process {
      width: 100%;
      height: 3px;
      background-color: @sui_color_gray_light2;
      border-radius: 2px;
      margin-top: 4px;
    }
    .item-content-tip__process-bg {
      height: 3px;
      background-color: @sui_color_main;
      border-radius: 2px;
      transition: width .5s linear;
    }
  }
  .cart_item-action {
    color: #222;
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
    display: inline-block;
    white-space: nowrap;
  }
  .coupon-addon__count-down {
    margin-top: 8px;
  }
}
.cart-coupon-addon__container.addon-dialog {
  color: @sui_color_gray_dark1;
  .coupon-addon__count-down {
    font-size: 12px;
  }
  .coupon-addon-content {
    margin-top: unset;
  }
}
</style>
