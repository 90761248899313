<template>
  <section
    v-if="topShippingTips || firstFreeShipping.firstFreeShippingTip"
    class="section shipping-sec"
  >
    <!-- 普通免邮、平台免邮、首单免邮、平台首单免邮 -->
    <!-- PS:根节点层级的注释会影响测试环境打包时, 父组件透传属性如class之类 -->
    <div class="section-l">
      <sui_icon_shipping_18px
        class="icon_shipping"
        size="18px"
      />
    </div>
    <div class="section-r">
      <p
        class="title"
        da-expose-code="cart-platform-shipping-block"
      >
        <span>{{ language.SHEIN_KEY_PC_15066.replace(':','') }}</span>
        <a
          v-if="showShipSupplement"
          class="operation"
          role="button"
          tabindex="0"
          da-event-expose="1-7-1-21"
          da-event-click="1-7-1-20"
          :data-shipping-free-type="firstFreeShipping.firstFreeShippingTip ? '' : isPlatform ? '0' : '3'"
          :data-mall_code="isPlatform ? '' : singleMallCode"
          @keydown.stop.enter="clickCartAdd"
          @click="clickCartAdd"
        >
          {{ language.SHEIN_KEY_PC_15215 }}
          <sui_icon_more_right_12px
            :is-rotate="locals.GB_cssRight"
            size="12px"
          />
        </a>
      </p>
      <ul class="contain not-fsp-element">
        <li>
          <template v-if="firstFreeShipping.firstFreeShippingTip">
            <p class="contain-tips">
              <!-- <span v-html="language.SHEIN_KEY_PC_18340"></span> -->
              <span v-html="firstFreeShipping.firstFreeShippingTip"></span>
            </p>
            <ClientOnly>
              <count-down-time
                v-if="firstFreeShipping.showCountDown && firstFreeShipping.countDownTime > 0"
                :time-stamp="firstFreeShipping.countDownTime"
                :is-left-time="true"
                show-type="inline-promotion"
                :show-end-tips="true"
              />
            </ClientOnly>
          </template>
          <span
            v-else
            v-html="topShippingTips"
          ></span>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter'
import { sui_icon_shipping_18px, sui_icon_more_right_12px } from '@shein-aidc/icon-vue3'

daEventCenter.addSubscriber({ modulecode: '1-7-1' })
const daEventExpose = daEventCenter.getExposeInstance()

export default {
  name: 'ShippingInfo',
  components: {
    sui_icon_shipping_18px,
    sui_icon_more_right_12px
  },
  computed: {
    ...mapState(['language', 'isPlatform', 'shippingInfo', 'ABTParams', 'locals']),
    ...mapGetters(['topShippingTips', 'firstFreeShipping', 'mallShippingAnalysisParam', 'singleMallCode', 'topShippingInfo']),
    showShipSupplement () {
      return this.topShippingInfo.showShipSupplement
    },
    shippingAnalysisParam() {
      let type = this.isPlatform ? 'platform' : this.singleMallCode
      return this.mallShippingAnalysisParam[type] || {}
    },
  },
  watch: {
    'topShippingTips': {
      deep: true,
      immediate: true,
      async handler (n, o) {
        if(typeof window === 'undefined') return
        if (n && n != o) {
          const { isLadder } = this.topShippingInfo
          daEventCenter.triggerNotice({
            daId: isLadder ? '1-7-5-5' : '1-7-5-3',
            extraData: {
              ...this.shippingAnalysisParam,
              mall_code: this.isPlatform ? '' : this.singleMallCode
            }
          })
        }
      }
    },
  },
  mounted() {
    this.initExpose()
  },
  methods: {
    ...mapMutations(['updateAddForCoupon', 'updateField']),
    clickCartAdd: function (){
      const addType = this.firstFreeShipping.firstFreeShippingTip ? 998 : 999
      this.updateField({ key: 'freeShippingAddOnType', value: this.isPlatform ? 'platform' : this.singleMallCode })
      this.updateField({ key: 'isMoreMallFreeShippingAddOn', value: false })
      this.updateAddForCoupon({ 
        show: true, 
        type: 'AddOnV2', // V2版本走重构后的弹窗
        promotion: {
          promotion_id: addType,
        },
      })
    },
    initExpose() {
      daEventExpose.subscribe && daEventExpose.subscribe({
        keycode: 'cart-platform-shipping-block`1-7-1-21',
      })
    },
  }
}
</script>

<style lang='less' scoped>
.shipping-sec {
  border: 0.5px solid rgba(25, 128, 85, 0.3);
}
.icon_shipping{
  color: #222222;
}
.contain-tips {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
