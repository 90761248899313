<template>
  <div
    class="qs-tag__wrapper"
    :style="{ ...styleConfig }"
  >
    <Icon
      name="sui_icon_qucikship_flat_24px"
      size="16px"
      class="qs-tag__icon"
    />
    <span class="qs-tag__text">{{ cartQsTagInfo.text }}</span>
    <span class="divider"></span>
    <span class="qs-tag__tip">{{ cartQsTagInfo.qsTimelinessInfo.tip }}</span>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Icon } from '@shein-aidc/icon-vue3'

export default {
  name: 'CartQuickShipTop',
  components: {
    Icon,
  },
  props: {
    styleConfig: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState(['cartQsTagInfo']),
  },
}
</script>

<style lang="less" scoped>
.qs-tag__wrapper {
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding: 12px 16px;
  background: #ECFCF3;
  border: 0.5px solid rgba(25, 128, 85, 0.20);
  color: #198055;
  line-height: 17px;
  font-size: 13px;
  .qs-tag__icon {
    margin-right: 2px;
  }
  .qs-tag__text {
    font-style: italic;
    font-weight: 590;
  }
  .divider {
    display: inline-block;
    width: .5px;
    height: 12px;
    background: rgba(25, 128, 85, 0.40);
    margin: 0 8px;
  }
  .qs-tag__tip {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    text-overflow: ellipsis;
    font-style: normal;
    font-weight: 400;
    color: #198055;
  }
}
</style>
