<template>
  <div
    :class="{
      'c-cart-top': num4components,
      'cart-top_show-bubble': showBubble,
    }"
  >
    <div
      v-if="num4components"
      class="top-container j-shoppromo-tip fsp-element"
    >
      <swiper-container
        v-if="switch2Swiper"
        ref="swiperContainer"
        init="false"
        class="top-wrapper"
      >
        <!-- eslint-disable vue/no-deprecated-slot-attribute -->
        <div slot="container-start">
          <div
            ref="swiperButtonPrev"
            class="swiper-button-prev j-r-prev swiper-button-disabled"
            tabindex="0"
            aria-label="prev page"
          >
            <sui_icon_more_left_18px
              size="18px"
            />
          </div>
        </div>
        <swiper-slide
          v-for="comp in components"
          :key="comp.name"
          class="cart-top-swiper-slide j-cart-top-swiper-slide"
        >
          <component
            :is="comp.name"
            v-bind="comp.bind"
          />
        </swiper-slide>
        <div slot="container-end">
          <div
            ref="swiperButtonNext"
            class="swiper-button-next j-r-next"
            tabindex="0"
            aria-label="next page"
          >
            <sui_icon_more_right_18px
              size="18px"
            />
          </div>
        </div>
      </swiper-container>
      <div
        v-else
        class="top-wrapper"
      >
        <template
          v-for="comp in components"
          :key="comp.name"
        >
          <component
            :is="comp.name"
            v-bind="comp.bind"
          />
        </template>
      </div>
    </div>
    <!-- <div
      class="j-shoppromo-tip-placeholder"
      style="height: 74px;display: none"
    ></div> -->
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { sui_icon_more_left_18px, sui_icon_more_right_18px } from '@shein-aidc/icon-vue3'
import { Navigation } from 'swiper/modules'
import FullPromotionInfoTip from './fullPromotionInfoTip.vue'
import ShippingInfoTip from './shippingInfoTip.vue'
import CouponAddon from '../cartCouponAddon/CouponAddon.vue'
import NewShippingInfoTip from './NewShippingInfoTip.vue'


export default {
  name: 'TopTpl',
  components: {
    FullPromotionInfoTip,
    ShippingInfoTip,
    CouponAddon,
    NewShippingInfoTip,
    sui_icon_more_left_18px,
    sui_icon_more_right_18px
  },
  data () {
    return {
      swiper: null,
      showBubble: false,
    }
  },
  computed: {
    ...mapState(['isPlatform', 'integrityPromotionInfo', 'shippingInfo']),
    ...mapGetters(['mallFullCourtMap', 'topShippingTips', 'firstFreeShipping', 'singleMallCode', 'CouponAddonStatus', 'isShowNewFreeShip', 'newFreeShippingInfo']),
    components() {
      const {
        showShipping,
        hasFullPromotion,
        CouponAddonStatus,
        singleMallCode,
        isPlatform,
      } = this
      const res = []
      // 单mall站点的免邮模块，包含免邮活动和运费券
      if (showShipping) {
        if (this.isShowNewFreeShip) {
          res.push({
            name: 'NewShippingInfoTip',
          })
        } else {
          res.push({
            name: 'ShippingInfoTip',
          })
        }
      }

      // 促销活动
      if (hasFullPromotion) {
        res.push({
          name: 'FullPromotionInfoTip',
          bind: {
            ref: 'fullPromotionTip',
            rootClassName: 'section',
            rootStyle: {
              padding: 0,
            },
            mallCode: singleMallCode,
            isSingleMall: !isPlatform,
            isSingleTips: !(showShipping || CouponAddonStatus), // 是否只有促销活动
            showPosition: 'top',
            showBubbleFn: this.handlerShowBubble
          }
        })
      }

      if (CouponAddonStatus) {
        res.push({
          name: 'CouponAddon',
        })
      }

      return res
    },
    fullCourtMap() {
      return this.isPlatform ? [] : this.mallFullCourtMap[this.singleMallCode] || []
    },
    showShipping() {
      if (this.isShowNewFreeShip) return this.newFreeShippingInfo
      return this.topShippingTips || this.firstFreeShipping.firstFreeShippingTip
    },
    /**
     * 包含全场活动：全场满减、 全场满折、全场返券； 或平台化活动：全场加价购、 全场满赠
     * @return {boolean}
     */
    hasFullPromotion() {
      return !!this.fullCourtMap.length
    },
    num4components() {
      return [this.showShipping, this.hasFullPromotion, this.CouponAddonStatus].filter(i => i).length
    },
    switch2Swiper() {
      if(this.num4components > 2) return true
      else return false
    }
  },
  watch: {
    showShipping(){
      if(this.hasFullPromotion && this.fullCourtMap.length > 1){
        this.$nextTick(()=>{
          this.$refs.fullPromotionTip?.resize()
        })
      }
    },
    switch2Swiper: {
      handler(val) {
        if (typeof window === 'undefined') return
        if (val) {
          this.$nextTick(() => {
            setTimeout(() => {
              this.initSwiper()
            }, 0)
          })
        } else {
          this.swiper?.destroy()
          this.swiper = null
        }
      },
      immediate: true,
    },
  },
  methods: {
    initSwiper() {
      if (!this.$refs.swiperContainer) return

      let swiperConfig = {
        modules: [Navigation],
        navigation: {
          prevEl: this.$refs.swiperButtonPrev,
          nextEl: this.$refs.swiperButtonNext,
        },
        direction: 'horizontal',
        slidesPerView: 'auto',
        slidesPerGroup: 1,
        roundLengths: false,
        observer: true,
        observeParents: true,
        injectStyles: [
          `
            .swiper-wrapper slot {
              display: flex;
              align-items: stretch;
            }
          `
        ],
      }
      Object.assign(this.$refs.swiperContainer, swiperConfig)
      this.$refs.swiperContainer.initialize?.()
      this.swiper = this.$refs.swiperContainer.swiper
    },
    handlerShowBubble(v) {
      this.showBubble = v
    }
  }
}

</script>
<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.top-container{
  padding: 0 0 10px 0;
  background-color: #f4f4f4;
  //.platform-top-wrapper{
  //  margin-bottom: 12px;
  //}
  .cart_item-header.platform-full-promotion{
      padding: 0;
      margin-bottom: 12px;
      & + .cart_item-header{
        margin-bottom: 0;
      }
    &:last-child{
      margin-bottom: 0;
    }

  }
}
.c-cart-top{
  position: relative;
  width: 100%;
  overflow: hidden;
  .top-wrapper{
    display: flex;
    width: 100%;
    .cart-top-swiper-slide {
      flex: 0 0 308px;
      & + .cart-top-swiper-slide {
        margin-left: 12px;
      }
      > .section {
        height: 100%;
      }
    }
  }
  .top-wrapper:not(swiper-container) {
    justify-content: space-between;
    gap: 10px;
    .section {
      width: unset;
      flex: 1;
      margin-right: unset;
    }
  }
  &.cart-top_show-bubble{
    overflow: initial;
  }
}
.qs-tag__wrapper {
  margin-bottom: 10px;
}
// 购物全场提示
.c-cart-top{
  .top-wrapper{
    .section{
      position: relative;
      padding: 12px 16px;
      .flexbox();
      word-break: break-word;
      flex: 0 0 308px;
      height: unset;
      width: 308px;
      .section-l{
        margin-right: 8px;
      }
      .section-r{
        flex: 1;
        .operation {
          color: #222222;
          font-weight: bold;
          font-size: 12px;
          cursor: pointer;
          &:hover{
            text-decoration: none;
          }
        }
      }
      .title{
        font-size: 14px;
        font-weight: bold;
        line-height: 25px;
        .flexbox();
        .space-between();
      }
      .contain{
        margin-top: 5px;
        li{
          position: relative;
          font-size: 13px;
          line-height: 14px;
          span{
            vertical-align:middle;
          }
          .operation{
            display: inline-block;
            cursor: pointer;
            &:hover{
              text-decoration: underline;
            }
          }
          .c-count-down-time{
            font-weight: normal;
            margin-top: 8px;
            font-size: 12px;
            color: @sui_color_highlight;
            .time-content{
              font-weight: normal;
              vertical-align: baseline;
              color: @sui_color_highlight;
              span{
                display: inline;
                font-weight: bold;
                color: @sui_color_highlight;
              }
            }
          }
          .promotion-slide-content{
            .flexbox();
            .space-between();
            span{
              flex: 1;
            }
          }
        }
      }
      &:last-child{
        .margin-r(0);
      }
      &.shipping-sec{
        background:@sui_color_safety_bg;

        .subtitle {
          line-height: 20px;
          color: #666;
          font-size: 13px;
          margin-top: 4px;
          .subtitle-tip {
            vertical-align: middle;
          }
        }
      }
      &.promotion-sec{
        border: 0.5px solid rgba(250, 99, 56, 0.3);
        background:@sui_color_promo_bg;
        &.hover-bubble{
          position: absolute;
          .left(0px);
          top: 0px;
          background: #FFF4EB /* rw: rgba(255, 153, 153, 0.08) */;
          z-index: @zindex-hack;
          box-shadow: 0px 2px 12px 0px rgba(111, 50, 0, 0.12);
          width: 100%;
          .contain{
            width: calc(~"100% + 1px");
            li{
              margin-top: 24px;
              &:first-child{
                margin-top: 0px;
              }
            }
          }
        }
      }
    }
    >*:not([class^='swiper']) {
      overflow: hidden;
    }
  }
}
</style>

<style lang="less" scoped>
swiper-container.top-wrapper {
  .swiper-button-prev,
  .swiper-button-next {
    top: 55%;
    width: 32px;
    height: 32px;
    background: rgba(255,255,255,.94);
    border-radius: 18px;
    font-size: 12px;
    text-align: center;
    vertical-align: unset;

    display: flex;
    align-items: center;
    justify-content: space-around;
    font-weight: normal;

    pointer-events: auto !important; /* stylelint-disable-line declaration-no-important */
    cursor: pointer;
    font-size: 18px;
    color: #222;
  }
  .swiper-button-prev {
    .left(5px);
  }
  .swiper-button-next {
    .right(5px);
  }
}
</style>
